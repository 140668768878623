import React from "react"
//import * as styles from "./VideoPlayer.module.scss"

type VideoPlayerProps = React.DetailedHTMLProps<React.VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>

const VideoPlayer = (props: VideoPlayerProps) => {
  //console.log('Rendering VideoPlayer', props)
  return <video playsInline controls {...props} />
}

export default VideoPlayer
