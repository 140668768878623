import React, { ReactElement, ReactNode } from "react"
import * as styles from "./MediaBlock.module.scss"

export type MediaBlockProps = {
  layout: MediaBlockLayout
  media: ReactElement
  caption?: ReactNode
  id?: string
}

export type MediaBlockLayout = "Normal" | "FullWidth" | "Tile"

const MediaBlock: React.FC<MediaBlockProps> = props => {
  //console.log('Rendering MediaBlock', props)

  const fullWidth = props.layout == "FullWidth" || props.layout == "Tile"

  return (
    <figure id={props.id} className={`${styles.container} ${fullWidth ? styles.fullWidth : "layout-container"}`}>
      <div className={styles.media}>{props.media}</div>

      {props.caption && (
        <figcaption className={styles.caption}>
          <small className="layout-container">{props.caption}</small>
        </figcaption>
      )}
    </figure>
  )
}

export default MediaBlock
