import { graphql } from "gatsby"
import React from "react"
import { slugify } from "../../../utils"
import VideoPlayer from "../../atoms/VideoPlayer"
import MediaBlock, { MediaBlockLayout } from "../../molecules/tileable-blocks/MediaBlock"
import DatoImage from "../DatoImage"
import * as styles from "./DatoMediaBlock.module.scss"

export type DatoMediaBlockProps = {
  media: Queries.DatoMediaFragment
  caption?: string
  layout: "Normal" | "FullWidth" | "Tile"
}

const DatoMediaBlock = (props: Queries.DatoMediaBlockFragment) => {
  //console.log('Rendering DatoMediaBlock', props)
  const { layout = "Tile" } = props

  if (!props.media) {
    return null
  }

  const mediaType = props.media?.mimeType?.split("/")[0]

  const fullWidth = layout === "FullWidth"

  return (
    <MediaBlock
      id={slugify(props.media.title || props.media.alt)}
      layout={layout as MediaBlockLayout}
      media={
        mediaType == "video" ? (
          <VideoPlayer
            src={props.media.video?.mp4Url ?? undefined}
            poster={props.media.video?.thumbnailUrl ?? undefined}
            controls={false}
            muted
            playsInline
            autoPlay
            loop
          />
        ) : (
          <DatoImage
            className={fullWidth ? styles.fullWidthImage : ""}
            image={props.media?.image}
            alt={props.media?.alt || ""}
            focalPoint={props.media?.focalPoint}
          />
        )
      }
      caption={props.caption}
    />
  )
}

export default DatoMediaBlock

export const query = graphql`
  fragment DatoMediaBlock on DatoCmsMediaBlock {
    id: originalId # Korjaus daton bugiin, jos käytetään structured textissä
    media {
      ...DatoMedia
    }
    caption
    layout
  }
`
